import {Customization, Session, SessionFile} from "../5.types/sessionsstate";
import {apiCall, GetParams, HttpMethod} from "./index";
import {User} from "../5.types/adminstate";

export interface GetSessionsParams extends GetParams {
    name?: string;
    state?: string;
    userId?: number | string;
    softDeleted?: string;
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetSessionsResponse = GetListResponse<Session>;

function transformToSession(obj: any): Session {
    const session: Session = {
        created: new Date(obj.created),
        end: obj.end && new Date(obj.end),
        start: obj.start && new Date(obj.start),
        id: obj.id,
        name: obj.name,
        state: obj.state,
        urls: 0,
        files: [],
        consumedCredits: obj.consumedCredits || 0,
        signingDate: obj.signingDate && new Date(obj.signingDate),
        lastHeartBeat: obj.lastHeartBeat && new Date(obj.lastHeartBeat),
        softDeleted: obj.softDeleted || false,
        customization: obj.customization,
    };
    if (obj.publicIp && obj.guacamoleToken) {
        session.connection = {
            tunnelUrl: `wss://${obj.dnsName}:9091`,
            token: `${obj.guacamoleToken}`,
        };
    }
    return session;
}

function transformToFile(obj: any): SessionFile {
    let name = "";
    if (obj.name) {
        name = obj.name.substring(obj.name.lastIndexOf("/") + 1);
    }
    return {
        category: obj.category,
        created: new Date(obj.created),
        id: obj.fileId,
        link: obj.link,
        name,
        sizeByte: obj.sizeByte,
    };
}

export async function getSessions(query: GetSessionsParams): Promise<GetSessionsResponse> {
    const res = await apiCall({urlComponent: "/sessions", query});
    const json = await res.json();
    return {
        results: json.results.map((o: any) => transformToSession(o)),
        total: json.total,
    };
}

export async function createSession(name: string, locale: string, customization: Customization, fullProxy: boolean = false): Promise<{session: Session | undefined, statusCode: number}> {
    try {
        const res = await apiCall({
            urlComponent: "/sessions", method: HttpMethod.POST, body: {
                name,
                fullProxy,
                description: "This is a session",
                locale,
                customization: {
                    fileContentOrId: customization.logoId || customization.logo?.content,
                    color: customization.color,
                    subtitle: customization.subtitle,
                },
            }
        });
        if (!res.ok) {
            return {session: undefined, statusCode: res.status};
        }
        const json = await res.json();
        return {session: transformToSession(json), statusCode: res.status};
    } catch (err) {
        return {session: undefined, statusCode: -1};
    }
}

export async function abortSession(id: string | number): Promise<void> {
    await apiCall({
        urlComponent: `sessionController/abortSession`, method: HttpMethod.POST, body: {
            sessionId: id,
        }
    });
}

export async function getSession(id: string | number, files: boolean = true): Promise<Session> {
    const res = await apiCall({urlComponent: `/sessions/${id}`});
    const json = await res.json();
    const session = transformToSession(json);
    if (files) {
        session.files = await getFiles(session) || [];
    }
    return session;
}

export async function deleteSession(id: string | number): Promise<void> {
    await apiCall({urlComponent: `/sessions/${id}`, method: HttpMethod.DELETE});
}

export async function getFiles(session: Session): Promise<SessionFile[]> {
    const res = await apiCall({urlComponent: `/sessions/${session.id}/files`});
    const json = await res.json();
    return json.map(transformToFile);
}

export async function getFile(session: Session, file: SessionFile | number): Promise<SessionFile> {
    const res = await apiCall({urlComponent: `sessions/${session.id}/files/${(typeof file === "number") ? file : file.id}`});
    const json = await res.json();
    return transformToFile(json);
}

export async function createArchiveLink(session: Session): Promise<string> {
    const res = await apiCall({
        urlComponent: `/sessions/${session.id}/files/archive:generatePublicUrl`,
        method: HttpMethod.POST,
    });
    const json = await res.json();
    return json.signedUrl;
}

export async function getDownloadFileString(session: Session): Promise<string | undefined> {
    const res = await apiCall({urlComponent: `/sessions/${session.id}/files`});
    const json = await res.json();
    if (json.length) {
        const res1 = await apiCall({urlComponent: `/sessions/${session.id}/files/${json[0].id}`});
        const json1 = await res1.json();
        return json1.link;
    } else {
        return undefined;
    }
}

export async function getReportUrl(session: Session, locale: string): Promise<{url: string}> {
    const res = await apiCall({urlComponent: `/sessions/${session.id}/reports/${locale}`});
    return await res.json();
}

export async function deleteReport(session: Session, locale: string): Promise<void> {
    await apiCall({urlComponent: `/sessions/${session.id}/reports/${locale}`, method: HttpMethod.DELETE});
}

export async function refundSession(session: Session, credits?: number): Promise<boolean> {
    const res = await apiCall({urlComponent: `/sessionController/refundCredits`, method: HttpMethod.POST, body: {
        sessionId: session.id,
        credits,
    }});
    return (await res.json()).refunded;
}

export async function changeOwner(session: Session, newUser: User): Promise<boolean> {
    const res = await apiCall({urlComponent: `/sessionController/changeOwner`, method: HttpMethod.POST, body: {
            sessionId: session.id,
            userId: newUser.userId,
        }});
    return (await res.json()).ownerChanged;
}

export async function terminateVMSession(session: Session): Promise<boolean> {
    const res = await apiCall({urlComponent: `/sessionController/terminateVM`, method: HttpMethod.POST, body: {
            sessionId: session.id,
        }});
    return (await res.json()).terminated;
}

export async function stopVMSession(session: Session): Promise<boolean> {
    const res = await apiCall({urlComponent: `/sessionController/stopVM`, method: HttpMethod.POST, body: {
            sessionId: session.id,
        }});
    return (await res.json()).stopped;
}

export async function failVMSession(session: Session): Promise<boolean> {
    await apiCall({urlComponent: `/sessionController/failVM`, method: HttpMethod.POST, body: {
            sessionId: session.id,
        }});
    return true;
}
