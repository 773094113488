import {LongPoller} from "../6.utils/LongPoller";
import {reverseMapFromMap} from "../6.utils/commons";

export enum SessionState {
    "VM_PREPARING" = "VM_PREPARING", // vm is being booted
    "VM_READY" = "VM_READY", // vm ready
    "STARTED" = "STARTED", // acquisition started
    "CLOSING" = "CLOSING",
    "ARTIFACTS_UPLOADED" = "ARTIFACTS_UPLOADED",
    "CLOSED" = "CLOSED",
    "VM_TERMINATED" = "VM_TERMINATED",
    "VM_ABORTED_ERROR" = "VM_ABORTED_ERROR",
    "VM_ABORTED_TIMEOUT" = "VM_ABORTED_TIMEOUT",
    "VM_ABORTED_MANUAL" = "VM_ABORTED_MANUAL",
    "VM_ABORTED_ERROR_TERMINATED" = "VM_ABORTED_ERROR_TERMINATED", // vm terminated after VM_ABORTED_ERROR
    "VM_ABORTED_TIMEOUT_TERMINATED" = "VM_ABORTED_TIMEOUT_TERMINATED", // vm terminated after VM_ABORTED_TIMEOUT
    "VM_ABORTED_MANUAL_TERMINATED" = "VM_ABORTED_MANUAL_TERMINATED", // vm terminated after VM_ABORTED_MANUAL
    "FATAL_FAILED" = "FATAL_FAILED", // an error occured while a forensic acquisition was running
    "FATAL_FAILED_TERMINATED" = "FATAL_FAILED_TERMINATED", // vm terminated after FATAL_FAILED
    "GURU_INTERVENTION" = "GURU_INTERVENTION", // an error occured while toolbox was uploading it artifacts
    "GURU_INTERVENTION_STOPPED" = "GURU_INTERVENTION_STOPPED", // vm is stopped after GURU_INTERVENTION
}

export enum SessionSimpleState {
    "INITIALIZING" = "INITIALIZING",
    "ABORTED" = "ABORTED",
    "READY" = "READY",
    "STARTED" = "STARTED",
    "FINALIZING" = "FINALIZING",
    "COMPLETED" = "COMPLETED",
    "FAILED" = "FAILED",
}

export const SessionStateMap = {
    [SessionState.CLOSING]: SessionSimpleState.FINALIZING,
    [SessionState.ARTIFACTS_UPLOADED]: SessionSimpleState.FINALIZING,
    [SessionState.CLOSED]: SessionSimpleState.COMPLETED,
    [SessionState.VM_ABORTED_MANUAL]: SessionSimpleState.ABORTED,
    [SessionState.VM_ABORTED_ERROR]: SessionSimpleState.ABORTED,
    [SessionState.VM_ABORTED_TIMEOUT]: SessionSimpleState.ABORTED,
    [SessionState.VM_ABORTED_MANUAL_TERMINATED]: SessionSimpleState.ABORTED,
    [SessionState.VM_ABORTED_ERROR_TERMINATED]: SessionSimpleState.ABORTED,
    [SessionState.VM_ABORTED_TIMEOUT_TERMINATED]: SessionSimpleState.ABORTED,
    [SessionState.VM_TERMINATED]: SessionSimpleState.COMPLETED,
    [SessionState.VM_PREPARING]: SessionSimpleState.INITIALIZING,
    [SessionState.VM_READY]: SessionSimpleState.READY,
    [SessionState.STARTED]: SessionSimpleState.STARTED,
    [SessionState.FATAL_FAILED]: SessionSimpleState.FAILED,
    [SessionState.FATAL_FAILED_TERMINATED]: SessionSimpleState.FAILED,
    [SessionState.GURU_INTERVENTION]: SessionSimpleState.FAILED,
    [SessionState.GURU_INTERVENTION_STOPPED]: SessionSimpleState.FAILED,
};

export const SessionFinalStates: Array<SessionState> = [
    SessionState.FATAL_FAILED_TERMINATED,
    SessionState.GURU_INTERVENTION_STOPPED,
    SessionState.VM_ABORTED_ERROR_TERMINATED,
    SessionState.VM_ABORTED_MANUAL_TERMINATED,
    SessionState.VM_ABORTED_TIMEOUT_TERMINATED,
    SessionState.VM_TERMINATED,
];

export function getPossibleStates(simpleStates: SessionSimpleState[], complement: boolean = false): SessionState[] {
    const possibleStates: Set<SessionState> = new Set();
    const reversedSessionStateMap = reverseMapFromMap(SessionStateMap);
    for (const ss of simpleStates) {
        reversedSessionStateMap[ss].map((s) => possibleStates.add(s as SessionState));
    }
    if (complement) {
        return Object.keys(SessionStateMap).filter((s) => !possibleStates.has(s as SessionState)) as SessionState[];
    } else {
        return Array.from(possibleStates);
    }
}

export const SimpleSessionStateMap = Object.keys(SessionSimpleState).reduce((previousValue, currentValue: string) => {
    previousValue[currentValue] = getPossibleStates([currentValue as SessionSimpleState]);
    return previousValue;
}, {} as {[key: string]: SessionState[]});

export interface SessionCustomization {

}

export interface SessionCustomization {
    logoFileId?: number;
    color?: string;
    subtitle?: string;
}

export interface Session {
    id: number;
    name: string;
    created: Date;
    urls: number;
    connection?: SessionConnection;
    state: SessionState;
    start: Date | undefined;
    end: Date | undefined;
    signingDate: Date | undefined;
    lastHeartBeat: Date | undefined;
    consumedCredits: number;
    files: SessionFile[];
    softDeleted: boolean;
    customization?: SessionCustomization;
}

export enum FileCategory {
    "DOWNLOAD" = "DOWNLOAD",
    "VIDEO" = "VIDEO",
    "SCREENSHOT" = "SCREENSHOT",
    "LOG" = "LOG",
    "MHTML" = "MHTML",

    "WACZ" = "WACZ",
    "PCAP" = "PCAP",
    "KEYS" = "KEYS",
    "DOWNLOAD_INDEX" = "DOWNLOAD_INDEX",
    "SCREENSHOT_INDEX" = "SCREENSHOT_INDEX",
    "XML" = "XML",
    "XML_NOT_SIGNED" = "XML_NOT_SIGNED",
}

export interface SessionFile {
    id: number;
    sizeByte: number;
    created: Date;
    name: string;
    category: FileCategory;
    link?: string;
}

export enum PollerName {
    STARTED = "started",
    CLOSED = "closed",
}

export interface NewSession {
    name?: string;
    fullProxy?: boolean;
    error?: {name: string, reconnect: boolean};
    acquiring: boolean;
    pollers: {[key: string]: LongPoller};
}

export interface SessionStats {
    remaining: number;
    used: number;
    completed: number;
    infinite: boolean;
}

export interface SessionConnection {
    tunnelUrl: string;
    token: string;
}

export interface SessionsState {
    openedModal: boolean;
    list?: Session[];
    total: number;
    stats?: SessionStats;
    newSession?: NewSession;
    session?: Session;
    detail?: Session;
}

export interface Customization {
    color?: string;
    logo?: { mime: string, content: string };
    logoId?: number;
    subtitle?: string;
}
